<template>
    <div>
        <div class="menuitem  hb-clr5">
            <div  v-if="this.menuitem.ref">
                <a :href="this.menuitem.ref" class="txt-clr1 item withcaret">
                    {{this.menuitem.label}}
                </a>
                <div v-if="this.menuitem.submenu" v-on:click="oncaretclick()" class="caret txt-clr1">
                    <i class="fa fa-caret-down"></i>
                </div>
            </div>
            <div v-else-if="this.menuitem.to"> 
                <router-link :to="this.menuitem.to" class="item withcaret">
                    {{this.menuitem.label}}
                </router-link>
                <div v-if="this.menuitem.submenu" v-on:click="oncaretclick()" class="caret  txt-clr1">
                    <i class="fa fa-caret-down"></i>
                </div>
            </div>
            <div v-else class="item txt-clr1">
                <div v-on:click="oncaretclick()" class="withcaret">
                    {{this.menuitem.label}}
                </div> 
                <div v-if="this.menuitem.submenu" v-on:click="oncaretclick()" class="caret  txt-clr1">
                    <i class="fa fa-caret-down"></i>
                </div>
            </div>
        </div>     

        <div v-if="subdisplayed">
            <verticalmenusubitem v-for="subitem in this.menuitem.submenu" :key="subitem.label" :menuitem="subitem">
            </verticalmenusubitem>
        </div>
    </div>
</template>

<script>
import verticalmenusubitem from "./verticalmenusubitem.vue";

export default {
    data: function(){
            return {
                submenudisplayed:false
            }
    },
    props:{
        menuitem:Object
    },
    components: {
        verticalmenusubitem
    },
    computed:{
        subdisplayed: function(){
            return this.submenudisplayed;
        }
    },
    methods:{
        oncaretclick:function(){
            let smd = this.submenudisplayed;
            this.submenudisplayed = !smd;
        }
    }
}
</script>

<style scoped>
.menuitem {
    display:block;
    padding:6px 16px;
    font-size: 20px;
}

.menuitem .item {
    cursor:pointer;
    text-decoration: none;
    display:inline-block;
}

.withcaret{
    display:inline-block;
}

.caret {
    display:inline-block;
    cursor:pointer;
    text-decoration: none;
    display:inline-block;
    padding-left:7px;
}

.submenublock {
    position:absolute;
    z-index:1;
    display:block;
    background-color: #1b3409;
    opacity: 0.8;
    padding:3px;
}
</style>