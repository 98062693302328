<template>
    <div class="viewcontainer">
        <nav id="navbar" class="sidebar b-clr5">
            <a href="#INTRO" class="bar-item padding-navlarge b-clr5 txt-clr1 htxt-clr0 hb-clr2">
                <div>INTRO</div>
                <i class="fa fa-home large-text"/>
            </a>
            <a v-for="vid in videos" v-bind:key="vid.position" v-bind:href="'#VIDEO'+vid.position" class="bar-item padding-navlarge b-clr5 txt-clr0 htxt-clr0 hb-clr2">
                 <div>VIDEO</div>
                 <span class="large-text">{{vid.position}}</span>
            </a>
            <a v-on:click="$emit('close',$event.target.value)" class="bar-item padding-navlarge b-clr5 txt-clr0 htxt-clr0 hb-clr2">
                <div>FERMER</div>
                <i class="fa fa-close large-text"/>
            </a>
        </nav>
        <div class="padding-large main" id="mainview">
            <div id="INTRO" class="b-clr1 txt-clr5">
                <div class="title txt-clr1 b-clr5">
                    {{coaching.title}}
                </div>
                <div class="intro" v-html="coaching.introduction">
                </div>
                <div class="centeredimage">
                    <img :src="coachinglargeImage" class="centeredimage"/>
                </div>
            </div>
            <div v-for="vid in videos" v-bind:key="vid.position" v-bind:id="'VIDEO'+vid.position">
                <videoview v-bind:video="vid"/>
            </div>
            <dynamicContent :dynamicContentList="coaching.dynamicContent" v-if="coaching.dynamicContent"/>
            <div class="conclusion b-clr4 txt-clr2" v-if="coaching.footer">
                <div v-html="coaching.footer"></div>
            </div>
            <div class="closebottom padding-navlarge  b-clr1 txt-clr3 htxt-clr0 hb-clr2"> 
                <a v-on:click="$emit('close',$event.target.value)">
                    <i class="fa fa-close large-text"/><span class="closetext">FERMER</span>
                
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import tool from "../assets/js/tool.js";
import videoview from "./VideoView.vue";
import dynamicContent from "./DynamicContentListView.vue";

export default {
    data: function(){
        return {
            videos:[],
            mainview:null,
            navBar:null,
            originOffset:0           
        }
    },
    methods:{
        getImageUrl(url){
            return tool.getUrl("/api/image/"+url);
        },
        handleScroll(){
            let rect = this.mainview.getBoundingClientRect();
            if (rect.top > 0){          
                this.navBar.style.position=null;
            } else {
                this.navBar.style.position="absolute";
                let offset = -rect.top+this.originOffset;
                this.navBar.style.top = offset+"px";
            }
        }
    },
    computed:{
        coachinglargeImage:function(){
            return this.getImageUrl(this.coaching.pictureUrl);
        }
    },
    emits: [
        "close"
    ],
    props:{
        coaching: {
            type:Object
        },
        user: {
            type: Object
        }
    },
    created:function(){
        let self = this;
        const coachId = this.coaching.RowKey;
        fetch(tool.getUrl("/api/videometa/"+coachId))
        .then(
            response => response.json()
        ).then(
            data => {
                self.videos = data.sort(tool.sortByPosition);
                self.videos.forEach(element => {
                    element.coachId = coachId
                });
            }
        );
        window.addEventListener('scroll', this.handleScroll);

        
    },
    mounted:function(){
        this.mainview = document.getElementById("mainview");
        this.navBar = document.getElementById("navbar");
        let winrect = document.body.getBoundingClientRect();
        let rect = this.mainview.getBoundingClientRect();
        this.originOffset = rect.top - winrect.top -250;
    },
    destroyed:function(){
        window.removeEventListener('scroll', this.handleScroll);
    },
    components:{
        videoview,
        dynamicContent
    }
}
</script>

<style scoped>

.sidebar {
    width:120px;
    text-align: center;
    text-decoration: none;
    /* position: sticky; */
    float:left;
    z-index:100;
}

.bar-item{
    width:100%;
    display:block;
    border:none;
    overflow: hidden;
    cursor:pointer;
    text-decoration: none;
}
.padding-navlarge {
    padding-top: 6px ;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
}
.large-text {
    font-size: 42px;
    vertical-align: middle;
}

.main {
    margin-left: 144px;
}

.title {
    text-align:center;
    vertical-align: middle;
    padding:24px;
    margin:0px auto;
    width:100%;
    font-size: 40px;
    /* box-shadow:0 4px 20px 0; */
}


.intro {
    padding:24px;
    margin:24px auto;
    width:90%;
    text-align: center;
}
.bandeau {
    height:263px;
    /* width:100%; */
    background-position: center;
    background-size: cover;
    overflow:hidden;
}

div.centeredimage {
    height:263px;
    width:384px;
    margin-right:auto;
    margin-left:auto;
}

img.centeredimage {
    height:263px;
    width:384px;
}

.conclusion{
    padding:24px;
}
.closebottom{
    display:none;
    text-align: center;
    margin-top:10px;
    
}
.closebottom .closetext{
    font-size:26px;
    padding-left:20px;
    vertical-align: middle;
}

@media screen and (max-width: 1150px){
    .sidebar {
        display:none;
    }
    .main {
        margin-left: 0px;
    }
    .closebottom{
        display: block;
    }
}
@media screen and (max-width: 700px) and (min-width: 450px){
    .title {
        padding:12px;
        margin:0px auto;
        /* width:350px; */
        font-size: 30px;
    }
}
@media screen and (max-width: 450px) {
    .title {
        padding:8px;
        margin:0px auto;
        /* width:200px; */
        font-size: 25px;
    }
}

</style>