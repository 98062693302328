<template>
    <div class="menuitem txt-clr1">
        <div>
            <a v-if="this.menuitem.ref" :href="this.menuitem.ref" class="txt-clr1 item">
                {{this.menuitem.label}}
            </a>
            <router-link v-else-if="this.menuitem.to" :to="this.menuitem.to" class="item">
                {{this.menuitem.label}}
            </router-link>
            <div v-else class="item" v-on:click="oncaretclick()">
                {{this.menuitem.label}}
            </div> 
            <div v-if="this.menuitem.submenu" class="caret" v-on:click="oncaretclick()">
                <i class="fa fa-caret-down"></i>
            </div>
        </div>     

        <div class="submenublock" v-if="subdisplayed">
            <horizontalmenusubitem v-for="subitem in this.menuitem.submenu" 
                :key="subitem.label" 
                :menuitem="subitem"
                v-on:clicked="onsubmenuclick()">
            </horizontalmenusubitem>
        </div>
        
    </div>
</template>

<script>
import horizontalmenusubitem from "./horizontalmenusubitem.vue"

export default {
    data: function(){
            return {
                submenudisplayed:false
            }
    },
    props:{
        menuitem:Object
    },
    components: {
        horizontalmenusubitem
    },
    computed:{
        subdisplayed: function(){
            return this.submenudisplayed;
        }
    },
    methods:{
        oncaretclick:function(){
            let smd = this.submenudisplayed;
            this.submenudisplayed = !smd;
        },
        onsubmenuclick:function(){
            this.submenudisplayed = false;
        } 
    }


}
</script>

<style scoped>
.menuitem {
    display:inline-block;
    padding:0px 16px;
}

.menuitem .item {
    cursor:pointer;
    text-decoration: none;
    display:inline-block;
    padding-left:3px;
}

.caret {
    cursor:pointer;
    text-decoration: none;
    display:inline-block;
    padding-left:7px;
}

.submenublock {
    position:absolute;
    z-index:1;
    display:block;
    background-color: #1b3409;
    opacity: 0.8;
}
</style>