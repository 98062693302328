<template>
    <div class="main txt-clr0 b-clr5 hb-clr4" @click="switchOpen">
        <div v-if="isOpened">&times;</div>

        <i v-else class="fa fa-bars"></i>
    </div>
</template>
<script>
export default {
    data: function(){
            return {
                opened:false
            }
    },
    emits: [
        'burgerclick'
    ],
    methods: {
        switchOpen: function(){
            this.opened = !this.opened;
            this.$emit('burgerclick',this.opened);
        }
    },
    computed:{
        isOpened: function(){
            return this.opened;
        }
    }
}
</script>
<style scoped>
.main{
    display:inline-block;
    height:48px;
    width:auto;
    font-size: 36px;
    vertical-align: middle;
    border:none;
    padding-left:10px;
    padding-right:10px;
    z-index:1000;
    position:fixed;
    opacity:0.8;
}
</style>