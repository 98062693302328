<template>
    <div class="verticalmenu">
       <burgermenu @burgerclick="switchDisplay">
        </burgermenu>
        <div :class="menudivclass">
            <verticalmenuitem v-for="anitem in menus" :menuitem="anitem" v-bind:key="anitem.label"/>
        </div>
    </div>
</template>

<script>
import burgermenu from "./burgermenu.vue";
import verticalmenuitem from "./verticalmenuitem.vue"

export default {
    props : {
        menus:Array
    },
    components: {
        burgermenu,
        verticalmenuitem
    },
    data: function () {
        return {
            isOpen: false
        }
    },
    methods:{
        switchDisplay: function(lisOpen){
            this.isOpen = lisOpen;
        }
    },
    computed:{
        menudivclass: function(){
            return this.isOpen ? "verticalmenuopeneddiv b-clr5" : "verticalmenudiv b-clr5"
        }
    }
}
</script>

<style scoped>
.verticalmenu {
    display: block;
}

.verticalmenudiv,
.verticalmenuopeneddiv{
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 48px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidepanel */
    opacity:0.9
}
.verticalmenudiv{
    width:0px;
}
.verticalmenuopeneddiv{
    width:100%;
}

@media screen and (min-width: 1200px){
    .verticalmenu {
        display: none;
}
}

</style>