<template>
    <div>
        <div class=" b-clr1">
            <div class="title txt-clr5">{{video.title}} </div>
            <div class="b-clr1 padding" v-if="video.preambule">
                <div class="preambule b-clr1 txt-clr5" v-html="video.preambule"> </div>
            </div>
            <div class="videocontainer">
                <div>
                    <videoplayer v-bind:video="video"/>
                    <div class="description txt-clr1" v-html="video.description">

                    </div>
                </div>
            </div>
        </div>
        <div class="separator b-clr1"></div>

    </div>
</template>
<script>
import videoplayer from "./VideoPlayer.vue";

export default {
    props:{
        video: {
            type:Object
        }
    },
    components:{
        videoplayer
    }
}
</script>
<style scoped>
.title {
    padding-top:20px;
    text-align:center;
    width:100%;
    font-size: 30px;
    /* margin:20px; */
}
.preambule {
    /* box-shadow:0 4px 20px 0; */
    padding:20px;
}
.padding {
    padding:30px 80px
}

.videocontainer{
    padding:30px
}

.videocontainer video {
    width:900px;
}
.videocontainer div {
    width:900px;
    margin-left:auto;
    margin-right:auto;
}

.description {
    text-align:center;
    font-size:24px;
    padding:20px;
}

.separator {
    height:8px;
    width:100%;
    opacity:0;
}

@media screen and (max-width: 1000px) and (min-width: 700px){
    .videocontainer video {
        width:600px;
    }
    .videocontainer div {
        width:600px;
        margin-left:auto;
        margin-right:auto;
    }
}
@media screen and (max-width: 700px) and (min-width: 400px) {
    .title {
        font-size:20px;
    }
    .preambule {
        padding:15px;
    }
    
    .description {
        font-size:16px;
        padding:10px;
    }

    .padding {
        padding:10px 30px
    }

    .videocontainer{
        padding:5px
    }
    .videocontainer video {
        width:340px;
    }
    .videocontainer div {
        width:340px;
        margin-left:auto;
        margin-right:auto;
    }
}
@media screen and (max-width: 400px){
    .title {
        font-size:16px;
    }
    .preambule {
        padding:10px;
    }

    .description {
        font-size:12px;
        padding:5px;
    }
    
    .padding {
        padding:10px 10px
    }

    .videocontainer{
        padding:0px
    }
    .videocontainer video {
        width:270px;
    }
    .videocontainer div {
        width:270px;
        margin-left:auto;
        margin-right:auto;
        padding-left:0px;
        padding-right:0px;
    }
}
</style>