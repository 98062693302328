<template>
  <div>
    <div :class="calculatedClass">
      <div class="shadow">
        <div class="scrolleditem">
          <div class="navbar-left">
            <verticalmenu :menus="this.displayedMenu"></verticalmenu>
          </div>
          <div class="header txt-clr1">
            <div class="sitelogodiv">
              <img v-bind:src="logo" alt="logo" class="sitelogo" />
            </div>
            <div class="headertitlegroup">
              <div class="headertitle">Back To Relaxation</div>
              <div class="headersubtitle">L'expérience du calme intérieur</div>
            </div>
          </div>

          <div class="navbar-right">
            <horizontalmenu :menus="this.displayedMenu">
            </horizontalmenu>
            <div class="topbutton">
              <roundedbtn v-if="user" :dark="true" :to="{name:'MyCoachings'}">
                Mes Produits
              </roundedbtn>
              
              <roundedbtn
                v-else
                v-on:clicked="showPopup()"
                :dark="true"
              >
                Connexion
              </roundedbtn>
            </div>

            <loginmenu v-bind:user="user">
            </loginmenu>
          </div>
        </div>
      </div>
    </div>
    <login v-bind:value="PopupShown" v-on:close="hidePopup()"></login>
  </div>
</template>

<script>
import login from "./Login.vue";
import tool from "../assets/js/tool.js";
import roundedbtn from "../microcomponents/roundedbutton.vue";
import Horizontalmenu from '../microcomponents/horizontalmenu.vue';
import Verticalmenu from '../microcomponents/verticalmenu.vue';
import loginmenu from '../microcomponents/loginmenu.vue'

export default {
  props: {
    user: Object,
  },
  data: function () {
    return {
      PopupShown: false,
      ScrollClass: "navbar initial",
      logo: '/logo.jpg',
      menu: null
    };
  },
  methods: {
    showPopup: function () {
      this.PopupShown = true;
    },
    hidePopup: function () {
      this.PopupShown = false;
    },
    gotoHome: function () {
      this.$router.push({ name: "Home" });
    },
    handleScroll: function () {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        this.ScrollClass = "navbar scrolled";
      } else {
        this.ScrollClass = "navbar initial";
      }
    },
  },
  components: {
    login,
    loginmenu,
    roundedbtn,
    Horizontalmenu,
    Verticalmenu
  },
  computed: {
    isUserAdmin: function () {
      return tool.isUserAdmin(this.user);
    },
    calculatedClass: function () {
      return this.ScrollClass;
    },
    displayedMenu:function(){
        return this.menu;
    }

  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted(){
    var self = this;
    tool.getMenus().then((res)=> {
        self.menu = res;
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.topbutton {
  display: inline;
  /* position:relative;
  top:-2px; */
}
.header {
  /* padding: 90px 90px;  Large padding which will shrink on scroll (using JS) */
  transition: 0.4s; /* Adds a transition effect when the padding is decreased*/
  top: 0; /* At the top */
  font-weight: bold;
  position: fixed;
  display:inline-block;

}
   .sitelogodiv {
        width:100px;
        height:120px;
        margin-right:15px;
        float:left;
    }
    .sitelogo {
        width:100px;
        height:120px;
    }

    .headertitlegroup{
      display:inline; 
      width:fit-content; 
      position:relative;
      top:30px;
      left: 0px;    
    }
    .headertitle {
        vertical-align: middle;
        /* display:inline;  */
        position:relative; 

        text-overflow: ellipsis;
    }

    .headersubtitle {
      vertical-align: middle;
      text-overflow: ellipsis;
      font-size:16px;
    }

    .scrolled .headertitlegroup{
        bottom:15px;
        text-overflow: ellipsis;
        left: 0px;
        top:0px;
    }

    .scrolled .headersubtitle {
      display:none;
    }

.scrolled .sitelogo {
        width:33px;
        height:40px;
    }

    .scrolled .sitelogodiv {
        width:33px;
        height:40px;
        margin-right:05px;
        display:inline-block;
    }

.navbar {
  overflow: hidden;
  width: 100%;
  height: 300px;
  background-image: url(/sunset_1920.jpg);
  background-size: cover;
  background-position-y: center;
  transition: 0.4s;
  z-index:99;
}

.navbar.scrolled {
  position: fixed;
  top: -250px;
}

.shadow {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0.7) 25%,
    transparent
  );
  width: 100%;
  height: 200px;
  transition: 0.4s;
  position: fixed;
}

.scrolled .shadow {
  top: 0px;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0,
    rgba(0, 0, 0, 0.7) 25%,
    transparent
  );
  height: 50px;
}

.scrolleditem {
  height: 50px;
  width: 100%;
}

.scrolleditem.scrolled {
  position: fixed;
  top: -150px;
  transition: 0.4s;
}



.initial .header {
  width:80%;
  padding-top: 100px;
  font-size: 35px;
  padding-left: 90px;
}

.scrolled .header {
  padding-top: 5px;
  font-size: 16px;
  padding-left: 10px;
  height: 40px;
  line-height: 41px;
  vertical-align: middle;
  width:100%;
}

.navbar-right {
  top: 5px;
  right: 5px;
  position: fixed;
  min-height:42px;
}

@media screen and (max-width: 1200px) {
  .scrolled .header {
    left:48px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    height: 125px;
  }
  .navbar.scrolled {
    top: -75px;
  }
  .headertitlegroup{
      top:3px;  
    }
  .shadow {
    height: 100px;
  }

  .scrolled .shadow {
    height: 50px;
  }
  .scrolleditem {
    height: 75px;
  }
  .initial .header {
    width:80%;
    padding-top: 50px;
    font-size: 25px;
    padding-left: 50px;
  }
  .sitelogodiv {
        width:50px;
        height:60px;
        margin-right:10px;
    }
  .sitelogo {
        width:50px;
        height:60px;
    }

}

@media screen and (max-width: 450px) {
  .headertitle{
    font-size:16px;
  }
  .headersubtitle {
      font-size:10px;
  }

  
  .scrolled .headertitle{
    font-size:10px;
  }
}
</style>