<template>
    <div class="main">
        <router-link :to="to" v-if="to">
            <div :class="calculatedclass">
                <slot></slot>
            </div>
        </router-link>
        <div v-else :class="calculatedclass" v-on:click="$emit('clicked')">
            <slot></slot>
        </div>
    </div>

</template>
<script>
export default {
  props: {
    to: Object,
    dark: Boolean,
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'big'].includes(value);
      }
    }
  },
  emits :[
    'clicked'
    ],
  computed: {
    calculatedclass: function () {
      const cls = this.dark ? "common darkclass "+this.size : "common lightclass "+this.size;
      return cls;
    },
  },
};
</script>
<style scoped>
.main {
  display:inline-block;
}

.main a {
  text-decoration:none;
}

.common {
  width: fit-content;
  font-style: normal;
  cursor: pointer;
  vertical-align: middle;
  transition: border-color 0.4s;
  font-size: 16px;
  border-style: solid;
  border-width:3px;
  margin-left:12px;
}

.normal {
  border-radius: 48px;
  border-width:3px;
  padding: 0px 16px;
  line-height: 35px;
}

.big {
  border-radius: 35px;

  border-width:4px;
  padding : 15px 30px;

}



.darkclass {
  color: #ebf7e3;
  background-color: #1b3409;
  opacity:1;
}

.darkclass:hover {
  border-color:#9BD770;
  color:#9BD770;
}

.lightclass {
  color: #1b3409;
  background-color: #ebf7e3;
  border-color: #1b3409;
}

.lightclass:hover {
  background-color: #9bd770;
  border-color:#9BD770;
}
</style>
