<template>
    <div class="submenu txt-clr5 b-clr1 hb-clr2">
      <a v-if="this.menuitem.ref" :href="this.menuitem.ref" class="submenulink txt-clr5">
          {{this.menuitem.label}}
      </a>
      <router-link v-else-if="this.menuitem.to" :to="generateto(this.menuitem.to)" class="submenulink txt-clr5">
          {{this.menuitem.label}}
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
      props:{
          menuitem:Object
      },
      methods:{
          generateto:function(orgto){
              return {name:orgto};
          }
      }
  
  }
  </script>
  
  <style scoped>
  .submenu{
      display: block;
      padding:6px 16px;
  }
  
  .submenulink {
      display: block;
      text-decoration: none;
  }
  </style>